import React from "react";
import Layout from "../../../components/Layout/layout";
import BlogPage from "../../../components/Blogs/index";
import SEO from "../../../components/SEO/seo";
const Blog = () => {
	return (
		<Layout>
			<SEO
				title="Blog- SchnelleinTaxi"
				meta="Online Taxi Neumünster to Kiel | Taxi neumünster to Hamburg"
				description="SchnelleinTaxi service operates in Germany  and provides professional taxi service to customers."
			/>
			<BlogPage />
		</Layout>
	);
};

export default Blog;
